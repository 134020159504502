interface OneTrustConsentProps {
  oneTrustId?: string;
}

export default function OneTrustConsent({ oneTrustId }: OneTrustConsentProps) {
  return (
    <>
      {oneTrustId && (
        <>
          <link
            rel="preload"
            as="script"
            href="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          />
          <script
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            type="text/javascript"
            charSet="UTF-8"
            data-domain-script={oneTrustId}
          ></script>
        </>
      )}
    </>
  );
}
